import clsx from "clsx";
import DateInput from "./date-input";
import NumberInput from "./number-input";
import { forwardRef } from "react";

function Input(
  {
    type,
    value,
    onChange,
    decimalScale,
    fixedDecimalScale,
    autoFocus,
    max,
    prefix,
    suffix,
    className,
    placeholder,
    onKeyDown,
    allowNegative,
    disabled,
    readOnly,
    onBlur,
  },
  ref,
) {
  switch (type) {
    case "date":
      return (
        <DateInput
          className={clsx(INPUT_CLASSNAME, className)}
          value={value}
          onChange={onChange}
          placeholder={placeholder}
        />
      );
    case "number":
      return (
        <NumberInput
          className={clsx(INPUT_CLASSNAME, className)}
          value={value}
          onChange={onChange}
          decimalScale={decimalScale}
          fixedDecimalScale={fixedDecimalScale}
          autoFocus={autoFocus}
          placeholder={placeholder}
          max={max}
          prefix={prefix}
          suffix={suffix}
          allowNegative={allowNegative}
          disabled={disabled}
          readOnly={readOnly}
          onBlur={onBlur}
        />
      );
    default:
      return (
        <input
          ref={ref}
          value={value ?? ""}
          onChange={(e) => onChange(e.target.value)}
          type={type}
          className={clsx(INPUT_CLASSNAME, className)}
          autoFocus={autoFocus}
          placeholder={placeholder}
          onKeyDown={onKeyDown}
          disabled={disabled}
          readOnly={readOnly}
        />
      );
  }
}

export const INPUT_CLASSNAME = clsx(
  "rounded-sm bg-slate-100 px-3 outline-none placeholder:text-sm placeholder:font-normal placeholder:italic hover:bg-slate-200 focus:bg-white focus:ring-2 focus:ring-cyan-500 disabled:stripe-1 disabled:cursor-not-allowed",
);

export default forwardRef(Input);
