import { useCallback, useEffect, useState } from "react";
import { NumericFormat } from "react-number-format";

export default function NumberInput({
  className,
  value: valueProp,
  onChange,
  decimalScale,
  autoFocus,
  placeholder,
  max,
  prefix,
  suffix,
  allowNegative,
  disabled,
  fixedDecimalScale,
  readOnly,
  onBlur,
}) {
  const [value, setValue] = useState(valueProp);
  const isAllowed = useCallback(
    (params) => {
      const allowed = (params.floatValue || 0) <= max;
      if (!allowed) {
        setValue(max);
        onChange(max);
        document.activeElement.style.color = "#ff0000";
        window.setTimeout(
          () => (document.activeElement.style.color = "inherit"),
          200,
        );
      }
      return allowed;
    },
    [max],
  );
  useEffect(() => {
    setValue(valueProp);
  }, [valueProp]);

  function onValueChange(values, { event }) {
    if (!event) return;
    const val = values.floatValue > max ? max : values.floatValue;
    onChange && onChange(val);
    setValue(values.floatValue);
    window.setTimeout(() => setValue(val), 0);
  }
  return (
    <NumericFormat
      value={value}
      onValueChange={onValueChange}
      decimalSeparator=","
      thousandSeparator={"."}
      decimalScale={decimalScale ?? 0}
      displayType="input"
      prefix={prefix}
      suffix={suffix}
      type="text"
      className={className}
      isAllowed={max && isAllowed}
      allowLeadingZeros={false}
      allowNegative={allowNegative === true}
      autoFocus={autoFocus}
      placeholder={placeholder}
      disabled={disabled}
      fixedDecimalScale={fixedDecimalScale}
      readOnly={readOnly}
      onBlur={onBlur}
    />
  );
}
