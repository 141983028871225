import Cleave from "cleave.js/react";
import { parse } from "date-fns";
import { useMemo } from "react";
import { formatDateToISO, fromIsoToTr } from "../utils";
const cleaveOptions = {
  date: true,
  delimiter: "/",
  datePattern: ["d", "m", "Y"],
};

export default function DateInput({ value: valueIso, onChange, className, placeholder }) {
  const valueTr = useMemo(() => {
    return valueIso && fromIsoToTr(valueIso);
  }, [valueIso]);
  function textboxOnChange(e) {
    switch (e.target.value.length) {
      case 10:
        onChange(convertTrToIso(e.target.value));
        return;
      case 0:
        onChange(null);
        return;
      default:
        break;
    }
  }

  function textboxOnBlur(e) {
    const today = new Date();
    const length = e.target.value.length;
    const currentMonth = today.getMonth();
    const currentYear = today.getFullYear();
    const value = e.target.value;

    switch (length) {
      case 10:
        const newIso = convertTrToIso(value);
        if (newIso !== valueIso) onChange(newIso);
        return;
      case 0:
        onChange(null);
        return;
      case 1:
        onChange(
          formatDateToISO(new Date(currentYear, currentMonth, parseInt(value))),
        );
        return;
      case 3:
        onChange(
          formatDateToISO(
            new Date(currentYear, currentMonth, parseInt(value.substr(0, 2))),
          ),
        );
        return;
      case 4:
        onChange(
          formatDateToISO(
            new Date(currentYear, 0, parseInt(value.substr(0, 2))),
          ),
        );
        return;
      case 6:
        onChange(convertTrToIso(value + currentYear.toString()));
        return;
      case 7:
      case 8:
      case 9:
        onChange(
          formatDateToISO(
            new Date(
              2000 + parseInt(value.substr(6)),
              parseInt(value.substr(3, 2)) - 1,
              parseInt(value.substr(0, 2)),
            ),
          ),
        );
        return;
      default:
        break;
    }
  }

  function convertTrToIso(v) {
    return formatDateToISO(parse(v, "dd/MM/yyyy", new Date()));
  }

  return (
    <Cleave
      onChange={textboxOnChange}
      onBlur={textboxOnBlur}
      className={className}
      options={cleaveOptions}
      value={valueTr}
      placeholder={placeholder}
    />
  );
}
