import clsx from "clsx";

export default function InputGroup({ children, errorMessage }) {
  return (
    <div
      className={clsx("group relative p-2")}
      data-has-error={errorMessage && true}
    >
      {children}

      {errorMessage && (
        <div className="absolute -bottom-2 left-3 rounded-sm rounded-r bg-red-600 px-1 text-xs text-white opacity-95">
          {errorMessage}
        </div>
      )}
    </div>
  );
}
