import MetotTbReload from "@/components/icon-center/MetotTbReload";
import MetotTiArrowBack from "@/components/icon-center/MetotTiArrowBack";
import { useRouter } from "next/navigation";
import MetotHiOutlineExclamation from "./icon-center/MetotHiOutlineExclamation";

export default function PageErrorMessage({ pageStatus }) {
  const router = useRouter();
  const notFound = pageStatus == "not-found";
  return (
    <div className="flex flex-col items-center pb-10">
      <MetotHiOutlineExclamation size={"5rem"} className={"orange-to-gray mt-5"} />

      <div className="text-slate-400 text-2xl font-semibold mt-4 text-center">

        {notFound
          ? <>Görüntülemek istediğiniz kayıt <br />sistemde bulunmuyor</>
          : "Hata Oluştu"
        }
      </div>

      <div className="flex justify-center gap-4 mt-10">
        {!notFound &&
          <>
            <button
              className="p-2  flex items-center gap-2 text-cyan-700 hover:scale-105 duration-500 font-semibold"
              onClick={() => window.location.reload()}>
              <MetotTbReload size={"1.3rem"} />
              Yeniden Dene
            </button>
            <div className="w-1 bg-slate-100"></div>
          </>}

        <button
          className="p-2 flex items-center gap-2 text-cyan-700 hover:scale-105 duration-500 font-semibold"
          onClick={() => router.back()}>
          <MetotTiArrowBack size={"1.3rem"} />
          Geri Dön
        </button>
      </div>
    </div>
  )
}
