"use client";
import clsx from "clsx";
import React, { useEffect, useRef, useState } from "react";
import { createPortal } from "react-dom";

export default function SlimLoading({ status }) {
  const modalContainer = useRef(document.getElementById("modalContainer"));

  const [hide, setHide] = useState(false);
  let ready = status !== "loading" && status !== "running";
  useEffect(() => {
    switch (status) {
      case "loading":
      case "running":
        setHide(false);
        break;
      default:
        window.setTimeout(() => setHide(true), 350);
        break;
    }
  }, [status]);

  return hide
    ? null
    : modalContainer.current &&
        createPortal(
          <div
            className="fixed left-0 right-0 top-0 z-50"
            style={{ height: 4 }}
          >
            <div
              className={clsx(
                "slim-loading",
                ready ? "!w-full" : "sl-animating",
              )}
            ></div>
          </div>,
          modalContainer.current,
        );
}
