import { format, formatISO, parseISO } from "date-fns";

export async function delay(ms) {
  return new Promise((resolve) => {
    window.setTimeout(() => {
      resolve();
    }, ms);
  });
}

export function getRandomInt(min, max) {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min + 1)) + min;
}

export function roundFloat2(num) {
  return Math.round((num + Number.EPSILON) * 100) / 100;
}

export function roundFloat4(num) {
  return Math.round((num + Number.EPSILON) * 10000) / 10000;
}

export function roundFloat6(num) {
  return Math.round((num + Number.EPSILON) * 1000000) / 1000000;
}

export function mySlowFunction(blockTime) {
  const now = performance.now();
  while (performance.now() - now < blockTime) { }
}

const moneyFormatter22 = new Intl.NumberFormat("tr-TR", {
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
});
export function formatMoney22(v) {
  return moneyFormatter22.format(v);
}

const moneyFormatter24 = new Intl.NumberFormat("tr-TR", {
  minimumFractionDigits: 2,
  maximumFractionDigits: 4,
});

export function formatMoney24(v) {
  return moneyFormatter24.format(v);
}

const moneyFormatter04 = new Intl.NumberFormat("tr-TR", {
  minimumFractionDigits: 0,
  maximumFractionDigits: 4,
});

export function formatMoney04(v) {
  return moneyFormatter04.format(v);
}

export function formatDateToISO(d) {
  return formatISO(d, { representation: "date" });
}

export function formatDateToTr(d) {
  return format(d, "dd/MM/yyyy");
}
export function fromIsoToTr(dIso) {
  return dIso && format(parseISO(dIso), "dd/MM/yyyy");
}

export function validateEMail(email) {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    );
}

export function capitalizeFirstLetter(string) {
  return string ? string.charAt(0).toLocaleUpperCase() + string.slice(1) : null;
}

export function putInBrackets(str) {
  return str && `(${str})`;
}

export function shake(element) {
  element.classList.remove("shake");
  window.setTimeout(() => element.classList.add("shake"), 10);
}

export function getContrastYIQ(hexcolor) {
  var r = parseInt(hexcolor.substring(1, 3), 16);
  var g = parseInt(hexcolor.substring(3, 5), 16);
  var b = parseInt(hexcolor.substring(5, 7), 16);
  var yiq = (r * 299 + g * 587 + b * 114) / 1000;
  return yiq >= 128 ? "black" : "white";
}

const rtf1 = new Intl.RelativeTimeFormat("tr", { numeric: "auto" });
export function formatRelatively(days) {
  return rtf1.format(days, "day");
  // return days === 0
  // 	? 'Bugün'
  // 	: days === 1
  // 		? 'Dün'
  // 		: days + ' gün önce'
}

export const MAX_MONEY = 999_999_999.99;

export function isAtBottom(currentTarget) {
  return (
    currentTarget.scrollTop + 10 >=
    currentTarget.scrollHeight - currentTarget.clientHeight
  );
}

