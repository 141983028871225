import HttpRequest from "@/components/httpRequest";
import _uniqueId from "lodash/uniqueId";
import { create } from "zustand";
import { immer } from "zustand/middleware/immer";

const initial =
  typeof window === "undefined"
    ? {}
    : {
      fullName: window.localStorage.getItem("fullName"),
      userApiAddress: window.localStorage.getItem("userApiAddress"),
      userToken: window.localStorage.getItem("userToken"),
    };

const useAppInfo = create(
  immer((set, get) => ({
    appMessages: [],
    appStatus: undefined,
    ...initial,
    saveButtonShakeNumber: 0,
    saveButtonFocusNumber: 0,
    shakeSaveButton() {
      set((state) => {
        state.saveButtonShakeNumber++;
      });
    },
    focusSaveButton() {
      set((state) => {
        state.saveButtonFocusNumber++;
      });
    },
    async getAppToken(appId) {
      const { userApiAddress, userToken } = get();

      const { appName, appApiAddress, appToken } = await getAppToken(
        userApiAddress,
        userToken,
        appId,
      );

      const userRights = await getUserRights(appApiAddress, appToken);

      window.sessionStorage.setItem("appApiAddress", appApiAddress);
      window.sessionStorage.setItem("appId", appId);
      window.sessionStorage.setItem("appName", appName);
      window.sessionStorage.setItem("appToken", appToken);
      window.sessionStorage.setItem("userRights", JSON.stringify(userRights));

      set((state) => {
        state.appApiAddress = appApiAddress;
        state.appId = appId;
        state.appName = appName;
        state.appToken = appToken;
        state.userRights = userRights;
      });
    },
    initializeApp(appInfo) {
      set((state) => {
        state.appApiAddress = appInfo.appApiAddress;
        state.appId = appInfo.appId;
        state.appName = appInfo.appName;
        state.appToken = appInfo.appToken;
        state.userRights = appInfo.userRights;
      });
    },

    setAppStatus(appStatus) {
      set((state) => {
        state.appStatus = appStatus;
      });
    },

    addAppMessage(message, type, duration) {
      const id = _uniqueId();
      const cssDuration = `${duration / 1000}s`;
      set((state) => {
        state.appMessages.unshift({ id, message, type, cssDuration });
      });

      window.setTimeout(() => {
        set((state) => {
          // state.appMessages = state.appMessages.filter((m) => m.id !== id);
          const index = state.appMessages.findIndex((m) => m.id === id);
          delete state.appMessages.splice(index, 1);
        });
      }, duration);
    },
    showErrorMessage(message, duration = 3000) {
      get().addAppMessage(message, "error", duration);
    },
    showSuccessMessage(message, duration = 3000) {
      get().addAppMessage(message, "success", duration);
    },
    showWarningMessage(message, duration = 3000) {
      get().addAppMessage(message, "warning", duration);
    },
  })),
);

export default useAppInfo;

function getAppToken(userApiAddress, userToken, appId) {
  return new HttpRequest(userApiAddress, "/get-app-token/" + appId)
    .addHeader("authorization", userToken)
    .post();
}

function getUserRights(appApiAddress, appToken) {
  return new HttpRequest(appApiAddress, "/get-user-rights")
    .addHeader("authorization", appToken)
    .get();
}

export function showSuccessMessage(message, duration) {
  useAppInfo.getState().showSuccessMessage(message, duration);
}
export function showErrorMessage(message, duration) {
  useAppInfo.getState().showErrorMessage(message, duration);
}
export function showWarningMessage(message, duration) {
  useAppInfo.getState().showWarningMessage(message, duration);
}

export function shakeSaveButton() {
  useAppInfo.getState().shakeSaveButton();
}
export function focusSaveButton() {
  useAppInfo.getState().focusSaveButton();
}
