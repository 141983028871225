import { getRecaptchaToken } from "@/components/grecaptcha";
import HttpRequest from "@/components/httpRequest";
import { validateEMail } from "@/components/utils";
import _isEmpty from "lodash/isEmpty";
import { create } from "zustand";
import { immer } from "zustand/middleware/immer";

const useLoginUnit = create(
  immer((set, get) => ({
    pageStatus: "loading",
    loginForm: { eMail: "taner@abc.com", password: "943011" },
    errors: {},
    validationWasMade: false,
    setPageStatus(pageStatus) {
      set((state) => {
        state.pageStatus = pageStatus;
      });
    },
    changeLoginForm(values) {
      set((state) => {
        state.loginForm = { ...state.loginForm, ...values };
      });

      const { validate, validationWasMade } = get();
      if (validationWasMade) validate();
    },

    validate() {
      const errors = validate(get().loginForm);
      set((state) => {
        state.errors = errors;
        state.validationWasMade = true;
      });
      return _isEmpty(errors);
    },

    async login() {
      const eMail = get().loginForm.eMail;
      const password = get().loginForm.password;
      const setPageStatus = get().setPageStatus;
      const changeLoginForm = get().setPageStatus;
      try {
        setPageStatus("running");
        const { userId, userApiAddress, userAccountStatus } =
          await getUserByEMail(eMail);

        if (userAccountStatus === 2) {
          setPageStatus("user-invited");
          return;
        }
        changeLoginForm({ userId, userApiAddress });

        const { userToken, fullName } = await login(
          userId,
          userApiAddress,
          password,
        );

        window.localStorage.setItem("fullName", fullName);
        window.localStorage.setItem("userApiAddress", userApiAddress);
        window.localStorage.setItem("userToken", userToken);
        setPageStatus("ready");
        return true;
      } catch (errorId) {
        setPageStatus(errorId);
        return false;
      }
    },
  })),
);

export default useLoginUnit;

const REQUIRED_MESSAGE = "Gerekli alan";
function validate(form) {
  const errors = {};

  if (!form.eMail) errors.eMail = REQUIRED_MESSAGE;
  else if (!validateEMail(form.eMail)) errors.eMail = "Geçersiz e-posta adresi";

  if (!form.password) errors.password = REQUIRED_MESSAGE;

  return errors;
}

async function getUserByEMail(eMail) {
  const reCaptchaToken = await getRecaptchaToken("GetUserByEMail");
  const request = new HttpRequest(
    process.env.NEXT_PUBLIC_HUBAPIADDRESS,
    "/hub/get-user-by-email",
  );
  return await request.postAsJson({
    appBrandId: "metot",
    eMail,
    reCaptchaToken,
  });
}

async function login(userId, userApiAddress, password) {
  const reCaptchaToken = await getRecaptchaToken("Login");
  return await new HttpRequest(userApiAddress, "/login").postAsJson({
    appBrandId: "metot",
    userId,
    password,
    sessionDuration: 1,
    reCaptchaToken,
  });
}
