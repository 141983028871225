export default function MetotTbReload({ className, size, style }) {
   const computedSize = size || "1em";
   return (
      <svg
         className={className}
         style={style}
         height={computedSize}
         width={computedSize}
         stroke="currentColor"
         fill="none" strokeWidth="2" viewBox="0 0 24 24"
         strokeLinecap="round"
         strokeLinejoin="round"
         xmlns="http://www.w3.org/2000/svg"><path d="M19.933 13.041a8 8 0 1 1 -9.925 -8.788c3.899 -1 7.935 1.007 9.425 4.747"></path><path d="M20 4v5h-5"></path></svg>
   )
}